import { useEffect } from "react";
import { FaEye, FaEdit, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import axiosClient from "../../axios";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useOrdersContext } from "../../hooks/useOrderContext";

const ListOrders = ({ goToOrderDetails, orderCategoryID }) => {
    const { orders, dispatch } = useOrdersContext();
    const { user } = useAuthContext();


    const handleDeleteClick = async (orderID) => {
        await axiosClient.delete('order/delete/' + orderID).then((result) => {
            if (result.status === 200) {
                toast.success("Order deleted");
                dispatch({
                    type: "DELETE_ORDER", payload: result.data
                })
            }
        }).catch(() => {
            toast.error("something wrong");
        })
    }

    useEffect(() => {
        const fetchSalesOrders = async () => {
            await axiosClient.get(`/order/${orderCategoryID}`).then(function (response) {
                if (response.status === 200) {
                    dispatch({
                        type: "SET_ORDERS",
                        payload: response.data
                    })
                }
            }).catch((error) => {
                console.error(error);
            })
        }

        if (user) {
            fetchSalesOrders()
        }
    }, [dispatch, user])
    return (
        <>
            <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                            <table className="min-w-full">
                                <thead className="border-b">
                                    <tr>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            s/n
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Reference
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Customer
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                        Source
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Warehouse
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            status
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                           Delivery
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Delivery Date
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            {/* Action */}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        orders?.map((order, index) => (
                                            <tr className="border-b" key={index}>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{index + 1}</td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {order?.reference_number}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {order?.customer?.first_name + ' ' + order?.customer?.last_name}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {order?.source}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {order?.outlet?.name}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {order?.status}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {order?.delivery_status}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {order?.deliveryDate}
                                                </td>

                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    <ul className="flex items-center justify-center">
                                                        <li className='py-1 px-2.5' onClick={() => goToOrderDetails(order)} ><FaEye /></li>
                                                        <li className='py-1 px-2.5'><FaEdit /></li>
                                                        {/* <li className='py-1 px-2.5' onClick={() => handleDeleteClick(order?.id)}><FaTrash /></li> */}
                                                        {/* <li className='py-1 px-2.5'>MAKE</li> */}

                                                    </ul>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ListOrders