import React, { useEffect, useState } from 'react';
import { Header } from '../../components';
import Receipt from '../../components/pdfs/Reciept';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import axiosClient from '../../axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const Reciepted = () => {
    const params = useParams();
    const [dataa, setDataa] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchReciept = async () => {
            setLoaded(false);
            try {
                const { data, status } = await axiosClient.get(`pos/customer-receipt/${params.id}`);
                if (status === 200) {
                    setLoaded(true);
                    setDataa(data);

                } else {
                    setError(true);
                }
            } catch (error) {
                setError(true);
            } finally {
                setLoaded(false);
            }
        };

        fetchReciept();
    }, [params]);

    if (error) {
        return <h4>An error occurred</h4>;
    }

    return (
        <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl">
            <Header title="PRINT RECEIPT" />

            {dataa ? (
                <React.Fragment>
                    <PDFViewer width="500" height="700">
                        <Receipt data={dataa} />
                    </PDFViewer>
                    <PDFDownloadLink document={<Receipt data={dataa} />} fileName="receipt.pdf">
                        {({ loading }) => (loading ? 'Loading...' : 'Download Receipt')}
                    </PDFDownloadLink>
                </React.Fragment>
            ) : (
                <h4>KEEP WAITING PLEASE</h4>
            )}
        </div>
    );
};

export default Reciepted;
