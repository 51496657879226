import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import DateFormat from '../Utils/DateFormat';

// import logoImage from './logo.png';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#E4E4E4',
        // padding: 20,
        width: '80mm', // Set the width to 80mm
        height: "117.3mm"
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 20,
    },

    logoContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 10, // Adjust the margin as needed
    },
    logo: {
        width: 100, // Increase the width as desired
        //  height: 80, // Increase the height as desired
    },
    // title: {
    //     fontSize: 18,
    //     fontWeight: 'bold',
    // },
    address: {
        fontSize: 10,
        marginBottom: 5,
        alignItems: 'center',
        textAlign: 'center', // Align the address text to center
    },
    section: {
        marginBottom: 10,
        textAlign: 'center', // Align the section text to center
    },
    item: {
        fontSize: 10,
        marginBottom: 5,
        textAlign: 'center', // Align the item text to center
    },
    total: {
        fontSize: 12,
        fontWeight: 'bold',
        marginTop: 10,
        marginBottom: 5,
        textAlign: 'center', // Align the total text to center
    },
    thankYou: {
        fontSize: 12,
        marginTop: 10,
        textAlign: 'center', // Align the thank you text to center
    },
    website: {
        fontSize: 12,
        marginBottom: 5,
        textAlign: 'center', // Align the website text to center
    },
});

const Receipt = ({ data }) => {
    const { amount, customer_name, pos_items, reference_number, createdAt } = data;

    const calculateTotal = () => {
        let total = 0;
        pos_items.forEach((item) => {
            const itemAmount = parseFloat(item?.amount);
            if (!isNaN(itemAmount)) {
                total += itemAmount;
            }
        });
        return total.toFixed(2);
    };

    return (
        <Document>
            <Page size={[227.5]} style={styles.page}>
                
                <View style={styles.header}>
                    <View style={styles.logoContainer}>
                        <Image source="https://imagedelivery.net/HbOJcomwWj9PRVewDVmVUw/4601dcab-46ba-4f0a-eca6-3892d2d43300/public" style={styles.logo} />
                        <Text style={styles.address}>
                            Nakenohs Blvd, No 40 Alexander Road, Ikoyi, Lagos, Nigeria
                        </Text>
                        <Text style={styles.address}>+234 908 472 0800, +234 811 624 8821</Text>
                        <Text style={styles.address}>{reference_number}</Text>
                    </View>
                </View>
                <View style={styles.section}>
                    <Text style={styles.item}>{new Date(createdAt).toLocaleString()}</Text>
                    <Text style={styles.item}>--------------------------</Text>
                    {pos_items?.map((item, index) => (
                        <Text key={index} style={styles.item}>
                            {item?.sold_product?.item?.name} -    {item?.sold_product?.code} - 1  - {parseFloat(item?.amount).toFixed(2)}
                        </Text>
                    ))}
                    <Text style={styles.item}>--------------------------</Text>
                    <Text style={styles.total}>Total: {calculateTotal()}</Text>
                    <Text style={styles.item}>--------------------------</Text>
                    <Text style={styles.thankYou}>
                        Thank you for your purchase, {customer_name}! We hope you enjoy your FH.
                    </Text>
                    <Text style={styles.website}>www.femihandbags.com</Text>
                </View>
            </Page>
        </Document>
    );
};

export default Receipt;
