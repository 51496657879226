import { useEffect } from "react";
import { FaEye, FaEdit, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import axiosClient from "../../axios";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCuttingTicketsContext } from "../../hooks/useCuttingTicket";
import DateFormat from "../Utils/DateFormat";
import StatusButton from "../Utils/StatusButton";
const CuttingTicketList = ({ goToCuttingTicket, setModalContent, setOpenEdit }) => {
    const { cuttingTickets, dispatch } = useCuttingTicketsContext();
    const { user } = useAuthContext();
    let navigate = useNavigate();

    const handleDeleteClick = async (cutting_ticket_id) => {
        const response = await axiosClient.delete(`cutting-ticket/delete/${cutting_ticket_id}`).then((result) => {
            if (result.status === 200) {
                toast.success("Cutting ticket deleted");
                dispatch({
                    type: "DELETE_CUTTING_TICKET",
                    payload: result.data
                })
            }
        }).catch(() => {
            toast.error("Something wrong, keep calm");
        })
    }

    const goToWorkOrderDetails = (id) => {

        id ? navigate(`/workorder/${id}`) : toast.error("no id found");
    }
    const fetchCuttingTickets = async () => {
        await axiosClient.get("cutting-ticket").then(function (response) {
            if (response.status === 200) {
                dispatch({
                    type: "SET_CUTTING_TICKETS",
                    payload: response.data
                })
            }
        }).catch((error) => {
            console.error(error);
        })
    }

    useEffect(() => {
        fetchCuttingTickets();


        if (user) {
            fetchCuttingTickets()
        }
    }, [user])
    return (
        <>
            <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                            <table className="min-w-full">
                                <thead className="border-b">
                                    <tr>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            S/N
                                        </th>

                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            Reference
                                        </th>
                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            purpose
                                        </th>

                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                            status
                                        </th>

                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-center">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        cuttingTickets?.map((cuttingTick, index) => (
                                            <tr className="border-b" key={index}>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{index + 1}</td>

                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    <span onClick={() => goToWorkOrderDetails(cuttingTick?.workorder?.id)}>{cuttingTick?.reference_number}</span>

                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {cuttingTick?.purpose}
                                                </td>

                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    <StatusButton status={cuttingTick?.status} />
                                                </td>

                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    <ul className="flex items-center justify-center">
                                                        <li className='py-1 px-2.5' onClick={() => goToCuttingTicket(cuttingTick)}><FaEye /></li>
                                                        <li className='py-1 px-2.5' onClick={() => { setOpenEdit(true); setModalContent(cuttingTick) }}><FaEdit /></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CuttingTicketList