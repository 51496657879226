import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from "./app/Layout";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Navbar, Footer, Sidebar, ThemeSettings } from "./components";
import { Customer, Employees, Home, Login, Orders, SalesInvoice, MaterialList, OrderDetails, AddSalesOrder, AddSalesInvoice, CreateProduct, EditProduct, RoleList, WareHousesList, StaffList, FinishedProducts, Stock } from "./pages";
import "./App.css";
import Spinner from './components/Spinner';
import { useStateContext } from './contexts/ContextProvider';
import ViewCustomer from './pages/Customer/ViewCustomer';
import OrderCategory from './pages/Orders/OrderCategory';
import Invoice from './pages/Orders/Invoice';
import ProductList from './pages/Product/ProductList';
import ViewCuttingTicket from "./pages/CuttingTicket/ViewCuttingTicket.js";
import OneCuttingTicket from './pages/CuttingTicket/OneCuttingTicket';
import WorkOrderIndex from './pages/Orders/WorkOrder/WorkOrderIndex';
import SalesOrder from './pages/Orders/Sales/SalesOrder';
import { useAuthContext } from './hooks/useAuthContext';
import OneProduct from './pages/Product/OneProduct';
import Bank from './pages/Account/Bank';
import WorkOrderDetails from './pages/Orders/WorkOrder/WorkOrderDetails';
import POS from './pages/PointOfSale/POS';
import Payments from "./pages/Account/Payments"
import PosPayments from "./pages/Account/PosPayments"
import Reciepted from './pages/PointOfSale/Reciepted';
import WebsiteOrders from './pages/Orders/Website/WebsiteOrders';
import WebsiteOrderDetails from './pages/Orders/Website/WebsiteOrderDetails';
import GiftCard from './pages/GiftCard/index.jsx';
import Expenses from './pages/Expense/index.jsx';
import Vendors from './pages/Expense/vendors.jsx';
import ExpenseCategory from './pages/Expense/expenseCategory.jsx';
// import ViewCuttingTicket from './pages/CuttingTicket/ViewCuttingTicket';
// import { CustomerProvider } from './contexts/CustomerProvider';
const App = () => {
  const { user } = useAuthContext();
  const { authLoading } = useStateContext();

  let routes;

  if (user && user.role === "999") {
    routes = (
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/orders" element={<Orders />} />
            <Route path='order/category' element={<OrderCategory />} />
            <Route path="/order/sales" element={<SalesOrder />} />
            <Route path="/order/website" element={<WebsiteOrders />} />
            <Route path="/order/website/:id" element={<WebsiteOrderDetails />} />
            <Route path='/invoice' element={<Invoice />} />
            <Route path="/employees" element={<Employees />} />
            <Route path="/cutting-ticket" element={<ViewCuttingTicket />} />
            <Route path="/cutting-ticket/:id" element={<OneCuttingTicket />} />
            <Route path="/order/invoices" element={<SalesInvoice />} />
            <Route path="/order/sales/add" element={<AddSalesOrder />} />
            <Route path="/order/invoice/add" element={<AddSalesInvoice />} />
            <Route path="/order/sales/:id" element={<OrderDetails />} />
            <Route path="/staffs" element={<StaffList />} />
            <Route path="/roles" element={<RoleList />} />
            <Route path="/customers" element={<Customer />} />
            <Route path="/customer/:id" element={<ViewCustomer />} />
            <Route path="/products" element={<ProductList />} />
            <Route path='/product/:id' element={<OneProduct />} />
            <Route path="/product/create" element={<CreateProduct />} />
            <Route path="/product/edit/:id" element={<EditProduct />} />
            <Route path='/workorder' element={<WorkOrderIndex />} />
            <Route path='/workorder/:id' element={<WorkOrderDetails />} />
            <Route path='/warehouse-list' element={<WareHousesList />} />
            <Route path='/banks' element={<Bank />} />
            <Route path="/materials" element={<MaterialList />} />
            <Route path="/finished-leather-goods" element={<FinishedProducts />} />
            <Route path="/stock" element={<Stock />} />
            <Route path="/p-o-s" element={<POS />} />
            <Route path="/payments" element={<Payments />} />
            <Route path="/pos-payments" element={<PosPayments />} />
            <Route path="/customer-reciept/:id" element={<Reciepted />} />
            <Route path='/gift-cards' element={<GiftCard />} />
            <Route path='/expenses' element={<Expenses />} />
            <Route path='/vendors' element={<Vendors />} />
            <Route path='/expense-categories' element={<ExpenseCategory />} />

          </Route>
        </Routes>
      </BrowserRouter>
    );
  } else if (user && user.role === "0") {
    routes = (
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  } else if (user && user.role === "805") {
    routes = (
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path='/banks' element={<Bank />} />
            {/* <Route path="/materials" element={<MaterialList />} /> */}
            <Route path="/finished-leather-goods" element={<FinishedProducts />} />
            {/* <Route path="/stock" element={<Stock />} /> */}
            <Route path="/p-o-s" element={<POS />} />
            {/* <Route path="/payments" element={<Payments />} /> */}
            <Route path="/pos-payments" element={<PosPayments />} />
            <Route path="/customer-reciept/:id" element={<Reciepted />} />
            <Route path='/expenses' element={<Expenses />} />
            <Route path='/vendors' element={<Vendors />} />
            <Route path='/expense-categories' element={<ExpenseCategory />} />
            <Route path="/products" element={<ProductList />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }

  else if (user && user.role === "6868") {
    routes = (
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/cutting-ticket" element={<ViewCuttingTicket />} />
            <Route path="/cutting-ticket/:id" element={<OneCuttingTicket />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  } else if (user && user.role === "7467") {
    routes = (
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/customers" element={<Customer />} />
            <Route path="/customer/:id" element={<ViewCustomer />} />
            <Route path="/products" element={<ProductList />} />
            <Route path='/product/:id' element={<OneProduct />} />
            <Route path="/product/create" element={<CreateProduct />} />
            <Route path="/product/edit/:id" element={<EditProduct />} />
            <Route path='/workorder' element={<WorkOrderIndex />} />
            <Route path='/workorder/:id' element={<WorkOrderDetails />} />

            <Route path="/materials" element={<MaterialList />} />
            <Route path="/finished-leather-goods" element={<FinishedProducts />} />

            <Route path="/orders" element={<Orders />} />
            <Route path='order/category' element={<OrderCategory />} />
            <Route path="/order/sales" element={<SalesOrder />} />
            <Route path='/invoice' element={<Invoice />} />
            <Route path="/order/invoices" element={<SalesInvoice />} />
            <Route path="/order/sales/add" element={<AddSalesOrder />} />
            <Route path="/order/invoice/add" element={<AddSalesInvoice />} />
            <Route path="/order/sales/:id" element={<OrderDetails />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }

  else if (authLoading) {
    routes = <Spinner />;
  } else {
    routes = <Login />;
  }

  return (
    <>
      {routes}
      <ToastContainer />
    </>
  );
};

export default App;


