import React, { useEffect, useState, useRef } from 'react';
import axiosClient from '../../axios';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';
import { useNavigate } from 'react-router-dom';
import MoneyFormat from '../Utils/MoneyFormat';
import ComponentToPrint from '../Utils/ComponentToPrint';

const PosPaymentList = () => {
    const [payments, setPayments] = useState(null);
    const [sortBy, setSortBy] = useState('monthly'); // Default sort
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(20); // Default items per page
    const [totalPages, setTotalPages] = useState(1); // To store total pages for pagination
    const [searchQuery, setSearchQuery] = useState(''); // Search query state
    const navigate = useNavigate();
    const componentRef = useRef();

    const fetchPayments = async () => {
        try {
            const response = await axiosClient.get(`/pos`, {
                params: { sortBy, page, limit, search: searchQuery },
            });
            if (response.status === 200) {
                setPayments(response.data.payments);
                setTotalPages(response.data.pagination.totalPages); // Set total pages
            }
        } catch (error) {
            toast.error("Could not fetch payments");
        }
    };

    const handleSortChange = (newSortBy) => {
        setSortBy(newSortBy);
        setPage(1); // Reset to first page on sort change
        fetchPayments();
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (page > 1) {
            setPage((prevPage) => Math.max(prevPage - 1, 1));
        }
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        fetchPayments();
    };

    const handlePrintReceipt = useReactToPrint({
        content: () => componentRef.current,
    });

    const gotoReceipt = (sale) => {
        sale.id ? navigate(`/customer-reciept/${sale.id}`) : toast.error("No ID found");
    };

    useEffect(() => {
        fetchPayments();
    }, [sortBy, page, limit, searchQuery]);

    return (
        <div className="w-full overflow-hidden rounded-lg shadow-lg p-4 bg-white">
            <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                <form onSubmit={handleSearchSubmit} className="mb-2 md:mb-0 w-full md:w-1/3">
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        placeholder="Search payments..."
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </form>
                <div className="w-full md:w-1/3">
                    <select
                        value={sortBy}
                        onChange={(e) => handleSortChange(e.target.value)}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                        <option value="yearly">Yearly</option>
                    </select>
                </div>
            </div>

            <div className="w-full overflow-x-auto mb-4">
                <table className="min-w-full bg-white">
                    <thead className="bg-gray-100 text-gray-600 text-sm leading-normal">
                        <tr>
                            <th className="py-3 px-6 text-left">Date</th>
                            <th className="py-3 px-6 text-left">Customer Name</th>
                            <th className="py-3 px-6 text-left">Amount Paid</th>
                          
                            <th className="py-3 px-6 text-left">Discount /Discount Percentage</th>
                            <th className="py-3 px-6 text-left">Actual Amount</th>
                            <th className="py-3 px-6 text-left">Sold Products</th>
                            <th className="py-3 px-6 text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 text-sm font-light">
                        {payments && payments.map((sale, index) => (
                            <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
                                <td className="py-3 px-6 text-left whitespace-nowrap">
                                    {new Date(sale.createdAt).toLocaleString()}
                                </td>
                                <td className="py-3 px-6 text-left whitespace-nowrap">
                                    {sale.customer_name || 'N/A'}
                                </td>
                                <td className="py-3 px-6 text-left whitespace-nowrap">
                                    <MoneyFormat amount={sale.amount} />
                                </td>
                                <td className="py-3 px-6 text-left whitespace-nowrap">
                                <MoneyFormat amount={sale.discount} /> / {sale?.discount_percent}%
                                </td>
                                <td className="py-3 px-6 text-left whitespace-nowrap">
                                    <MoneyFormat amount={sale?.sub_total} />
                                </td>
                                <td className="py-3 px-6 text-left whitespace-nowrap">
                                    {sale.pos_items.map((item, idx) => (
                                        <div key={idx} className="flex items-center">
                                            <img
                                                src={item.sold_product.item.image}
                                                alt={item.sold_product.item.name}
                                                className="w-10 h-10 rounded-full mr-2"
                                            />
                                            <div>
                                                <div className="font-bold">{item.sold_product.item.name}</div>
                                                <div>{item.sold_product.code}</div>
                                                <div className="text-green-600">
                                                    <MoneyFormat amount={item.amount} />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </td>
                                <td className="py-3 px-6 text-center">
                                    <button
                                        onClick={() => gotoReceipt(sale)}
                                        className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
                                    >
                                        View Receipt
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="flex justify-between items-center">
                <button
                    onClick={handlePrevPage}
                    disabled={page === 1}
                    className="bg-gray-300 px-4 py-2 rounded-md mr-2 disabled:opacity-50"
                >
                    Previous
                </button>
                <span>{page}</span>
                <button
                    onClick={handleNextPage}
                    disabled={page === totalPages}
                    className="bg-gray-300 px-4 py-2 rounded-md disabled:opacity-50"
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default PosPaymentList;
