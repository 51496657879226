import { useEffect, useRef } from "react";
import Modal from '../../Modal';
import { useWareHousesContext } from "../../../hooks/useWareHouseContext";
import axiosClient from "../../../axios";
import { toast } from "react-toastify";
import { useFinishedProductContext } from "../../../hooks/useFinishedProductsContext";


const Transfer = ({ isOpen, setOpen, modalContent, fetchFinishedProducts }) => {
    const refWareHouse = useRef(null);
    const { dispatch, wareHouses } = useWareHousesContext();
    const { finished_Products, dispatch: fp } = useFinishedProductContext();

    useEffect(() => {
        const fetchWareHouses = async () => {
            try {
                const response = await axiosClient.get("warehouse");
                if (response.status === 200) {
                    dispatch({
                        type: "SET_WARE_HOUSES",
                        payload: response.data
                    })
                }
            } catch (error) {
                toast.error("Something went wrong")
            }
        }
        fetchWareHouses();
    }, [dispatch])

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("id", modalContent?.item_warehouse?.id);
        console.log("ref", refWareHouse.current.value);

        if (modalContent?.item_warehouse?.id == refWareHouse.current.value) {
            toast.error("You selected the same warehouse");
            setOpen(false)
            return;
        }

        const data = {
            "warehouse_id": refWareHouse.current.value,
        }

        try {
            // setIsLoading(true);
            // const response = await axiosClient.put(`warehouse/${warehouse_id}`, data);
            const response = await axiosClient.post(`finished-products/transfer/${modalContent?.id}`, data);
            if (response.status == 200) {
                console.log("RESPONSE DATA", response.data)
                toast.success("Finished Product Transfered");
                fetchFinishedProducts();
                setOpen(false);
                // fp({
                //     type: "UPDATE_FINISHED_PRODUCTS",
                //     payload: response.data
                // })
            } else {
                Promise.reject();
            }
        } catch (error) {
            console.error("errors", error); 
            toast.error("Error - " + " " + error?.response?.data?.message);
        }


    }

    return (
        <Modal isOpen={isOpen} close={() => setOpen(false)} modalhead="Status">
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="p-6 space-y-6">
                    <div className="grid grid-cols-1 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="first-name" className="text-sm font-medium text-gray-900 block mb-2">Status</label>
                            <select required ref={refWareHouse} defaultValue={modalContent?.wareHouse?.name} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5">
                                <option value="">Select A WareHouse</option>
                                {wareHouses && wareHouses.map((w) => (
                                    <option key={w.id} value={w.id}>{w?.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-span-12 sm:col-span-3">
                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                                Transfer
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    )
}

export default Transfer;
