import { useState } from 'react';

const ExpenseFilter = ({ onFilterChange, vendors, expenseCategories, warehouses }) => {
    const [expenseCategory, setExpenseCategory] = useState('');
    const [vendor, setVendor] = useState('');
    const [warehouse, setWarehouse] = useState('');
    const [search, setSearch] = useState('');

    const handleFilterChange = () => {
        onFilterChange({ expenseCategory, vendor, warehouse, search });
    };

    return (
        <div className="flex space-x-4">
            <select value={expenseCategory} onChange={e => setExpenseCategory(e.target.value)} className="border p-2">
                <option value="">All Categories</option>
                {Array.isArray(expenseCategories) && expenseCategories.map((category, index) => (
                    <option key={index} value={category.id}>{category.name}</option>
                ))}
            </select>
            <select value={vendor} onChange={e => setVendor(e.target.value)} className="border p-2">
                <option value="">All Vendors</option>
                {Array.isArray(vendors) && vendors.map((vendor, index) => (
                    <option key={index} value={vendor.id}>{vendor.business_name}</option>
                ))}
            </select>
           
            <select value={warehouse} onChange={e => setWarehouse(e.target.value)} className="border p-2">
                <option value="">All Warehouses</option>
                {Array.isArray(warehouses) && warehouses?.map((warehouse, index) => (
                    <option key={index} value={warehouse.id}>{warehouse?.name}</option>
                ))}
            </select>
            <input
                type="text"
                value={search}
                onChange={e => setSearch(e.target.value)}
                placeholder="Search"
                className="border p-2"
            />
            <button onClick={handleFilterChange} className="bg-blue-500 text-white p-2 rounded">Filter</button>
        </div>
    );
};

export default ExpenseFilter;
